import React from 'react';
import * as s from './Pagination.scss';
import {ArrowLeft, ArrowRight} from '../../../icons/dist';

export interface PaginationProps {
  prev: PaginationItem;
  next: PaginationItem;
}

export interface PaginationItem {
  text: string;
  href?: string;
  onClick?(event): void;
}

export const enum DataHooks {
  Pagination = 'pagination',
}

export class Pagination extends React.Component<PaginationProps> {
  private renderLink(item: PaginationItem, rel: 'prev' | 'next'): JSX.Element {
    const {href, onClick} = item;

    const content = (
      <>
        {rel === 'prev' ? (
          <>
            <ArrowLeft />
            {item.text}
          </>
        ) : (
          <>
            {item.text}
            <ArrowRight />
          </>
        )}
      </>
    );

    return (
      <a rel={rel} href={href} className={s[rel]} onClick={onClick}>
        {content}
      </a>
    );
  }

  public render() {
    const {prev, next} = this.props;

    return (
      <div data-hook={DataHooks.Pagination} className={s.pagination}>
        {this.renderLink(prev, 'prev')}
        {this.renderLink(next, 'next')}
      </div>
    );
  }
}
