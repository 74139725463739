import React from 'react';
import {Breadcrumbs} from './Breadcrumbs/Breadcrumbs';
import {withGlobalProps, ProvidedGlobalProps, withDirection} from '../../providers/globalPropsProvider';
import {PaginationProps, Pagination} from './Pagination/Pagination';
import cx from 'classnames';
import s from './Navigation.scss';
import {LeftArrow} from '../../icons/dist';
import {ProductPagePaginationItem} from '../../types/app-types';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

export interface NavigationProps {
  className?: string;
  type?: NavigationType;
}

export const enum NavigationType {
  Short,
  Long,
}

export const enum NavigationDataHooks {
  ShortBreadcrumbs = 'short-breadcrumbs',
}

@withGlobalProps
@withTranslations('globals.texts')
@withDirection
export class Navigation extends React.Component<NavigationProps & ProvidedGlobalProps & IProvidedTranslationProps> {
  public static defaultProps = {
    type: NavigationType.Long,
  };

  private navigationProps(): PaginationProps {
    const {
      t,
      globals: {
        navigate,
        pagination: {prevProduct = {} as ProductPagePaginationItem, nextProduct = {} as ProductPagePaginationItem},
      },
    } = this.props;

    return {
      prev: {
        text: t('BREADCRUMBS_PAGINATION_PREVIOUS'),
        href: prevProduct.fullUrl,
        onClick: e => {
          e.preventDefault();
          navigate(prevProduct);
        },
      },
      next: {
        text: t('BREADCRUMBS_PAGINATION_NEXT'),
        href: nextProduct.fullUrl,
        onClick: e => {
          e.preventDefault();
          navigate(nextProduct);
        },
      },
    };
  }

  private renderBack() {
    const {
      globals: {siteUrl, pagePath, hideNavigationUrls, isRTL},
      t,
    } = this.props;

    const previousPage = pagePath[pagePath.length - 2];
    let href = previousPage ? previousPage.url : siteUrl;
    const pageName = previousPage ? previousPage.name : t('BREADCRUMBS_HOME');
    const iconClass = cx({[s.shortBreadcrumbsRTL]: isRTL});

    if (hideNavigationUrls) {
      href = undefined;
    }

    return (
      <a href={href} data-hook={NavigationDataHooks.ShortBreadcrumbs} className={s.shortBreadcrumbs}>
        <LeftArrow className={iconClass} />
        <span>{t('BREADCRUMBS_BACK_TO_PAGE_LINK', {pageName})}</span>
      </a>
    );
  }

  private renderBreadcrumbs() {
    const {
      globals: {pagePath, hideNavigationUrls},
    } = this.props;

    const path = [...pagePath];

    if (hideNavigationUrls) {
      path.forEach(p => {
        if (p.url) {
          p.url = '';
        }
      });
    }

    return <Breadcrumbs path={path} />;
  }

  private renderPagination() {
    const {next, prev} = this.navigationProps();
    return (next.href || prev.href) && <Pagination next={next} prev={prev} />;
  }

  public render() {
    const {className, type} = this.props;

    return (
      <nav data-hook="navigation" className={cx(className, s.navigation)}>
        <div className={s.breadcrumbs}>
          {type === NavigationType.Short ? this.renderBack() : this.renderBreadcrumbs()}
        </div>
        {type === NavigationType.Long && this.renderPagination()}
      </nav>
    );
  }
}
