import * as React from 'react';
import classNames from 'classnames';
import s from './ProductImage.scss';
import {IImageDimension, IMedia} from '../../../../types/productDef';
import {ProvidedGlobalProps, withGlobalProps} from '../../../../providers/globalPropsProvider';
import {ProductImage as Image} from '@wix/wixstores-client-common-components/dist/src/ProductImage/ProductImage';
import {ImageMode} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import {getMediaUrl} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {OldProductImage as OldImage} from '@wix/wixstores-client-common-components/dist/src/OldProductImage/OldProductImage';
import {IProductMediaItem} from '@wix/wixstores-graphql-schema/dist/es/src';

export interface ProductImageItemProps extends ProvidedGlobalProps {
  mediaItem: IProductMediaItem;
  imageMode: ImageMode;
  withMagicZoom: boolean;
  productName: string;
  dimensions: IImageDimension;
  mountedToDOM: boolean;
  isSelected: boolean;
  imageLoaded(): any;
}

export interface ProductImageItemState {
  imageLoaded: boolean;
}

export enum DataHook {
  ProductImageItem = 'product-image-item',
  Image = 'product-image',
}

@withGlobalProps
export class ProductImage extends React.Component<ProductImageItemProps, ProductImageItemState> {
  public state = {imageLoaded: false};

  private readonly getMainImageUrl = (targetDimensions: {width: number; height: number}, isSSR = false): string => {
    const {imageMode, mediaItem} = this.props;
    return getMediaUrl(
      mediaItem as IMedia,
      targetDimensions || (mediaItem as IMedia),
      {
        isSSR,
        imageMode,
      },
      this.props.globals.experiments.jpgExperiment
    );
  };

  private readonly onImageLoad = () => {
    const {imageLoaded} = this.props;
    imageLoaded();
    this.setState({imageLoaded: true});
  };

  private readonly getImageContainerStyle = () => {
    const {imageLoaded} = this.state;
    const {isSEO} = this.props.globals;

    return imageLoaded || isSEO
      ? {}
      : {
          backgroundImage: `url(${this.getMainImageUrl(null, true)})`,
        };
  };

  public render() {
    const {
      imageMode,
      mountedToDOM,
      isSelected,
      withMagicZoom,
      mediaItem,
      dimensions,
      productName,
      imageLoaded,
      globals: {
        isSEO,
        experiments: {isUnifiedProductImage, isSSRImageImprovements, jpgExperiment},
      },
    } = this.props;

    const isNeedToBeMountedToDOM = mountedToDOM || isSEO;
    const imageClass = classNames([
      s.media,
      {
        [s.selected]: isSelected,
        [s.mediaWithZoom]: withMagicZoom,
      },
    ]);

    return (
      <>
        {isUnifiedProductImage ? (
          <Image
            mediaItem={mediaItem as IMedia}
            imageMode={imageMode}
            productName={productName}
            imageLoaded={imageLoaded}
            isSEO={isSEO}
            mountedToDOM={mountedToDOM}
            dimensions={dimensions}
            withSSRDimensions={isSSRImageImprovements}
            className={imageClass}
            altText={mediaItem.altText}
            containerDataHook={DataHook.ProductImageItem}
            imgDataHook={DataHook.Image}
            withJpgExperiment={jpgExperiment}
          />
        ) : (
          <OldImage
            imageMode={imageMode as any}
            productName={productName}
            mountedToDOM={isNeedToBeMountedToDOM}
            imageLoaded={this.onImageLoad}
            style={this.getImageContainerStyle()}
            className={imageClass}
            containerDataHook={DataHook.ProductImageItem}
            imgDataHook={DataHook.Image}
            altText={mediaItem.altText}
            src={this.getMainImageUrl(dimensions)}
          />
        )}
      </>
    );
  }
}
