import style from './ZoomBox.scss';
import {ZoomIcon} from '../../../../icons/dist';
import React from 'react';
import {getMagicZoomNodeId} from '../MainMediaUtils';
import * as _ from 'lodash';

export interface ZoomBoxProps {
  magicZoom: any;
  size: number;
  selectedIndex: number;
  src: string;
  mountedToDOM: boolean;
  shouldBeUpdated: boolean;
}

export enum DataHook {
  zoomBoxContainer = 'zoom-box-container',
}

export class ZoomBox extends React.Component<ZoomBoxProps> {
  public componentDidUpdate(prevProps: Readonly<ZoomBoxProps>) {
    const {magicZoom, selectedIndex, mountedToDOM, shouldBeUpdated} = this.props;
    const targetNodeId = getMagicZoomNodeId(selectedIndex);
    if (mountedToDOM && magicZoom) {
      if (!shouldBeUpdated) {
        magicZoom.start(targetNodeId);
      } else if (!_.isEqual(prevProps, this.props)) {
        if (shouldBeUpdated) {
          magicZoom.update(targetNodeId, this.props.src, this.props.src);
        }
        magicZoom.start(targetNodeId);
      }
    }
  }

  public componentWillUnmount(): void {
    const {magicZoom, selectedIndex, mountedToDOM} = this.props;
    const targetNodeId = getMagicZoomNodeId(selectedIndex);
    if (mountedToDOM && magicZoom) {
      magicZoom.stop(targetNodeId);
    }
  }

  public render() {
    const {size} = this.props;

    return (
      <div
        data-hook={DataHook.zoomBoxContainer}
        className={style.zoomBoxContainer}
        style={{width: `${size}px`, height: `${size}px`}}>
        <ZoomIcon size={`${size * 0.65}`} className={style.zoomIcon} viewBox={`0 0 ${size} ${size}`} />
      </div>
    );
  }
}
