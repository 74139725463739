import React from 'react';

import {Cell} from '../Cell/Cell';
import {Navigation, NavigationType} from '../../Navigation/Navigation';
import {ProductGalleryLayout} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayout';
import {ProductDescription} from '../../ProductDescription/ProductDescription';
import {InfoSectionLayout} from '../../InfoSectionLayout/InfoSectionLayout';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductSocial} from '../../ProductSocial/ProductSocial';
import {ProductName} from '../../ProductName/ProductName';
import {ProductSku} from '../../ProductSku/ProductSku';
import classNames from 'classnames';
import s from './MobileLayout.scss';
import {LayoutComponentProps, LayoutConfig} from '../../../types/app-types';
import {ProductPriceContainer} from '../../ProductPriceContainer/ProductPriceContainer';
import {ErrorTooltipPlacement, MarginBottom} from '../../../constants';
import {createLayoutConfigWithDefaults} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayoutUtils';
import {convertCssValueToConfig} from '../../../commons/utils';
import {withGlobalProps} from '../../../providers/globalPropsProvider';

@withGlobalProps
export class MobileLayout extends React.Component<LayoutComponentProps> {
  public render() {
    const {product, settings, globals} = this.props;
    const galleryClasses = classNames(s.gallery, {[s.galleryWithNavigation]: product.media.length > 1});
    const isMobileProductDescriptionPosition = globals.experiments.isMobileProductDescriptionPosition;
    const dimensions: LayoutConfig['dimensions'] =
      globals.experiments.isSSRImageImprovements && globals.isSSR
        ? {
            mainMedia: {
              widthConf: convertCssValueToConfig(s.sharedStyleVariables_galleryWidth),
            },
            thumbnails: {
              widthConf: convertCssValueToConfig(s.sharedStyleVariables_galleryWidth),
              heightConf: {num: 50, unit: 'px'},
            },
          }
        : undefined;

    const CellDescription = () => (
      <Cell className={s.description}>
        <ProductDescription description={product.description} />
      </Cell>
    );

    return (
      <article className={s.container}>
        {settings.shouldShowNavigation && <Navigation className={s.navigation} type={NavigationType.Short} />}
        <Cell className={galleryClasses}>
          <ProductGalleryLayout
            product={product}
            layoutConfig={createLayoutConfigWithDefaults({
              marginBottom: MarginBottom.LARGE,
              withImageRatio: true,
              withMediaBorder: true,
              swipeToScroll: true,
              dimensions,
            })}
            {...settings}
          />
        </Cell>
        <main className={s.row}>
          <header>
            {settings.shouldShowSku && (
              <Cell className={s.sku}>
                <ProductSku />
              </Cell>
            )}
            <Cell className={s.name}>
              <ProductName name={product.name} />
            </Cell>
            {settings.shouldShowPrice && (
              <Cell className={s.price}>
                <ProductPriceContainer />
              </Cell>
            )}
          </header>

          {!isMobileProductDescriptionPosition && (
            <>
              <CellDescription />
            </>
          )}
          <Cell className={s.options}>
            <ProductOptions
              errorPlacement={ErrorTooltipPlacement.Bottom}
              shouldShowQuantity={settings.shouldShowQuantity}
              shouldShowAddToCartButton={settings.shouldShowAddToCartButton}
            />
          </Cell>
          {isMobileProductDescriptionPosition && (
            <>
              <CellDescription />
            </>
          )}

          {settings.shouldShowInfoSection && (
            <Cell className={s.info}>
              <InfoSectionLayout />
            </Cell>
          )}
          {settings.shouldShowSocialNetwork && (
            <Cell className={s.social}>
              <ProductSocial />
            </Cell>
          )}
        </main>
      </article>
    );
  }
}
