import React from 'react';
import {InfoSectionLayoutId} from '../../constants';
import {CollapseInfoSection} from './Layouts/CollapseInfoSection/CollapseInfoSection';
import {StackedInfoSection} from './Layouts/StackedInfoLayout/StackedInfoSection';
import {ColumnsInfoSection} from './Layouts/ColumnsInfoLayout/ColumnsInfoSection';
import {TabsInfoSection} from './Layouts/TabsInfoLayout/TabsInfoSection';
import {ProvidedGlobalProps, withDirection, withGlobalProps} from '../../providers/globalPropsProvider';
import {
  geColumnNumberFromGlobalProps,
  geShowDividerFlagFromGlobalProps,
  geTabFontFromGlobalProps,
  getAdditionalInfoFromGlobalProps,
  getDirectionFromGlobalProps,
  getInfoSectionLayoutTypeFromGlobalProps,
  getOnActiveBIEventHandler,
  getIsRtlFromGlobalProps,
} from './InfoSectionLayoutUtils';
import {InfoSectionEvent} from '../../types/app-types';
import {IProductInfo} from '@wix/wixstores-graphql-schema';

export interface InfoSectionProps extends ProvidedGlobalProps {
  infoSectionItems: IProductInfo[];
}

@withGlobalProps
@withDirection
export class InfoSectionLayout extends React.Component<ProvidedGlobalProps> {
  private readonly handleBIEvent = (BIEvent: InfoSectionEvent) => {
    const onActive = getOnActiveBIEventHandler(this.props);
    onActive(BIEvent);
  };

  private readonly renderLayout = () => {
    const layoutId = getInfoSectionLayoutTypeFromGlobalProps(this.props);
    const infoSectionItems = getAdditionalInfoFromGlobalProps(this.props);
    const shouldShowDivider = geShowDividerFlagFromGlobalProps(this.props);
    const numberOfColumns = geColumnNumberFromGlobalProps(this.props);
    const tabFontStyle = geTabFontFromGlobalProps(this.props);
    const dir = getDirectionFromGlobalProps(this.props);
    const isRTL = getIsRtlFromGlobalProps(this.props);
    const updateLayout = this.props.globals.updateLayout;

    switch (layoutId) {
      case InfoSectionLayoutId.Collapse:
        return (
          <CollapseInfoSection
            infoSectionItems={infoSectionItems}
            handleBIEvent={this.handleBIEvent}
            updateLayout={updateLayout}
          />
        );
      case InfoSectionLayoutId.Stacked:
        return <StackedInfoSection infoSectionItems={infoSectionItems} shouldShowDivider={shouldShowDivider} />;
      case InfoSectionLayoutId.Columns:
        return (
          <ColumnsInfoSection
            infoSectionItems={infoSectionItems}
            numberOfColumns={numberOfColumns}
            shouldShowDivider={shouldShowDivider}
            isRTL={isRTL}
          />
        );
      case InfoSectionLayoutId.Tabs:
        return (
          <TabsInfoSection
            infoSectionItems={infoSectionItems}
            handleBIEvent={this.handleBIEvent}
            tabFontStyle={tabFontStyle}
            direction={dir}
            isRTL={isRTL}
          />
        );
    }
  };

  public render() {
    return <div dir="ltr">{this.renderLayout()}</div>;
  }
}
