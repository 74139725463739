import React from 'react';
import s from './ColumnsInfoSection.scss';
import {ColumnsInfoItem} from './InfoSectionColumn/ColumnsInfoItem';
import {InfoSectionProps} from '../../InfoSectionLayout';
import {IProductInfo} from '@wix/wixstores-graphql-schema';

export enum DataHook {
  ColumnsInfoSection = 'columns-info-section',
}

export interface ColumnsInfoSectionProps extends InfoSectionProps {
  infoSectionItems: IProductInfo[];
  shouldShowDivider: boolean;
  numberOfColumns: number;
  isRTL?: boolean;
}

export class ColumnsInfoSection extends React.Component<ColumnsInfoSectionProps> {
  public render() {
    const {infoSectionItems, shouldShowDivider, numberOfColumns, isRTL} = this.props;
    let calcNumberOfColumns = numberOfColumns;
    if (numberOfColumns === 3 && infoSectionItems.length < 3) {
      calcNumberOfColumns = 2;
    }
    if (numberOfColumns >= 2 && infoSectionItems.length < 2) {
      calcNumberOfColumns = 1;
    }

    return (
      <ul data-hook={DataHook.ColumnsInfoSection} className={s.columns} dir={isRTL ? 'rtl' : 'ltr'}>
        {infoSectionItems.map((infoItem, index) => {
          return (
            <ColumnsInfoItem
              key={infoItem.id}
              description={infoItem.description}
              title={infoItem.title}
              index={index}
              shouldShowDivider={shouldShowDivider}
              numberOfColumns={calcNumberOfColumns}
            />
          );
        })}
      </ul>
    );
  }
}
