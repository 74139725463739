import React from 'react';
import {withGlobalProps, ProvidedGlobalProps, withDirection} from '../../providers/globalPropsProvider';
import s from './ProductDescription.scss';
import {defaultFontSize} from '../../constants';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

export enum DataHook {
  DescriptionWrapper = 'description-wrapper',
  ContentWrapper = 'content-wrapper',
  Description = 'description',
  ShowMoreOrLess = 'show-more-or-less',
}

interface ProductDescriptionState {
  lineHeight: number;
  height: number;
  isCollapsedStatus: boolean;
}
export interface ProductDescriptionProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  description: string;
}

@withGlobalProps
@withTranslations('globals.texts')
@withDirection
export class ProductDescription extends React.Component<ProductDescriptionProps, ProductDescriptionState> {
  private readonly preRef: React.RefObject<HTMLPreElement>;

  constructor(props) {
    super(props);
    const fontSize =
      (props.globals.style.styleParams.fonts.productPage_descriptionFontStyle &&
        props.globals.style.styleParams.fonts.productPage_descriptionFontStyle.size) ||
      defaultFontSize;
    this.state = {
      isCollapsedStatus: true,
      height: 0,
      lineHeight: Math.ceil(fontSize * 1.5),
    };
    this.preRef = React.createRef();
  }

  public componentDidMount() {
    this.setHeights();
  }

  private readonly setHeights = () => {
    const {description} = this.props;
    if (description) {
      this.setState({
        height: this.preRef.current.clientHeight,
      });
    }
  };

  public componentDidUpdate() {
    this.props.globals.updateLayout && this.props.globals.updateLayout();
  }

  private readonly isShowMoreOrLess = (): boolean => {
    return this.props.globals.style.styleParams.numbers.productPage_descriptionVisibleLineNumbers > 2;
  };

  public isCollapsed(): boolean {
    return this.state.isCollapsedStatus && this.isShowMoreOrLess();
  }

  public toggleDescription(): void {
    const {isCollapsedStatus} = this.state;
    this.setState({isCollapsedStatus: !isCollapsedStatus});
  }

  public isNeedToCollapse(): boolean {
    return this.state.height > this.getCollapsedContentHeight();
  }

  private getCollapsedContentHeight(): number {
    const {productPage_descriptionVisibleLineNumbers} = this.props.globals.style.styleParams.numbers;
    const {lineHeight} = this.state;
    return lineHeight * productPage_descriptionVisibleLineNumbers;
  }

  public getContentHeightStyle(): string {
    if (this.isCollapsed()) {
      return `${this.getCollapsedContentHeight()}px`;
    }
    return '';
  }

  private readonly getShowMoreOrLess = () => {
    const {t} = this.props;
    return (
      this.isShowMoreOrLess() &&
      this.isNeedToCollapse() && (
        <button
          aria-hidden="true"
          className={s.showMoreOrLess}
          onClick={() => this.toggleDescription()}
          data-hook={DataHook.ShowMoreOrLess}>
          {this.isCollapsed() ? t('DESCRIPTION_SHOW_MORE') : t('DESCRIPTION_SHOW_LESS')}
        </button>
      )
    );
  };

  public render() {
    const {description} = this.props;
    const {lineHeight} = this.state;
    return !description ? null : (
      <section data-hook={DataHook.DescriptionWrapper} className={s.description}>
        <div className={s.descriptionWrapper}>
          <div
            className={s.contentWrapper}
            data-hook={DataHook.ContentWrapper}
            style={{maxHeight: this.getContentHeightStyle(), lineHeight: `${lineHeight}px`}}>
            <pre
              ref={this.preRef}
              data-hook={DataHook.Description}
              className={s.content}
              dangerouslySetInnerHTML={{__html: description}}
            />
          </div>
          {this.getShowMoreOrLess()}
        </div>
      </section>
    );
  }
}
