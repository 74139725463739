import React from 'react';
import {SocialVendor} from '../../../constants';
import styles from './SocialIcon.scss';
import {Facebook} from '../../../icons/dist/components/Facebook';
import {Google} from '../../../icons/dist/components/Google';
import {Twitter} from '../../../icons/dist/components/Twitter';
import {Fancy} from '../../../icons/dist/components/Fancy';
import {Pinterest} from '../../../icons/dist/components/Pinterest';
import {MobileShare} from '../../../icons/dist/components/MobileShare';

export interface SocialIconProps {
  className?: string;
  label: string;
  onClick?: Function;
  type: SocialVendor;
}

const socialIconMap = {
  [SocialVendor.Facebook]: Facebook,
  [SocialVendor.Google]: Google,
  [SocialVendor.Fancy]: Fancy,
  [SocialVendor.Pinterest]: Pinterest,
  [SocialVendor.Twitter]: Twitter,
  [SocialVendor.MobileNative]: MobileShare,
};

export class SocialIcon extends React.PureComponent<SocialIconProps> {
  private readonly onClick = (e): void => {
    const {onClick} = this.props;

    if (onClick) {
      onClick(e);
    }
  };

  public render() {
    const {type, label, className} = this.props;
    const Icon = socialIconMap[type];
    const classnames = styles[type];

    return (
      <a onClick={this.onClick} title={label} aria-label={label} className={className}>
        <Icon className={classnames} />
      </a>
    );
  }
}
