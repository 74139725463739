import React from 'react';

import {Cell} from '../Cell/Cell';
import {ProductGalleryLayout} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayout';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductName} from '../../ProductName/ProductName';
import {ProductSku} from '../../ProductSku/ProductSku';

import s from './QuickViewLayout.scss';
import {LayoutComponentProps} from '../../../types/app-types';
import {ProductPriceContainer} from '../../ProductPriceContainer/ProductPriceContainer';
import {createLayoutConfigWithDefaults} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayoutUtils';

export const QuickViewLayout: React.FunctionComponent<LayoutComponentProps> = ({product, settings}) => (
  <main className={s.row}>
    <section className={s.col}>
      <Cell className={s.gallery}>
        <ProductGalleryLayout product={product} layoutConfig={createLayoutConfigWithDefaults()} {...settings} />
      </Cell>
    </section>
    <section className={s.col}>
      <Cell>
        <ProductName name={product.name} />
      </Cell>

      <Cell className={s.sku}>
        <ProductSku />
      </Cell>

      <Cell className={s.price}>
        <ProductPriceContainer />
      </Cell>

      <Cell className={s.options}>
        <ProductOptions
          shouldShowAddToCartButton={settings.shouldShowAddToCartButton}
          shouldShowQuantity={settings.shouldShowQuantity}
        />
      </Cell>
    </section>
  </main>
);
