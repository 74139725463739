import React from 'react';
import s from './NumberInputSpinner.scss';
import classnames from 'classnames';

export interface NumberInputSpinnerProps {
  defaultValue?: number;
  isFocused?: boolean;
  max?: number;
  min?: number;
  onChange?: Function;
  step?: number;
  title?: string;
  value?: number;
}

interface NumberInputSpinnerState {
  value: number;
}

const NumberInputSpinnerDefaultProps: Partial<NumberInputSpinnerProps> = {
  defaultValue: 1,
  isFocused: false,
  max: Infinity,
  min: 1,
  onChange: () => null,
  step: 1,
  title: '',
};

export class NumberInputSpinner extends React.Component<NumberInputSpinnerProps, NumberInputSpinnerState> {
  public numberInputSpinnerRef;
  public static defaultProps = NumberInputSpinnerDefaultProps;

  constructor(props) {
    super(props);
    const {value, defaultValue} = props;
    this.state = {
      value: value !== undefined ? value : defaultValue,
    };
    this.numberInputSpinnerRef = React.createRef();
  }

  public componentDidMount() {
    if (this.props.isFocused) {
      this.focusNumberInputSpinner();
    }
  }

  private readonly focusNumberInputSpinner = () => {
    this.numberInputSpinnerRef.current.focus();
  };

  private readonly onUp = () => {
    const {value} = this.state;
    const {defaultValue, max, step, onChange} = this.props;
    const prevValue = value !== undefined ? value : defaultValue;
    const nextValue = Math.min(prevValue + step, max);

    this.setState({
      value: nextValue,
    });

    onChange(nextValue);
  };

  private readonly onDown = () => {
    const {value} = this.state;
    const {defaultValue, min, step, onChange} = this.props;
    const prevValue = value !== undefined ? value : defaultValue;
    const nextValue = Math.max(prevValue - step, min);

    this.setState({
      value: nextValue,
    });

    onChange(nextValue);
  };

  private readonly onChange = e => {
    const {onChange} = this.props;
    const {value: currentValue} = this.state;

    const userInputValue = Number(e.target.value);
    const nextValue = isNaN(userInputValue) ? currentValue : userInputValue;

    this.setState({
      value: nextValue,
    });

    onChange(nextValue);
  };

  public render() {
    const {title, max, min, defaultValue} = this.props;
    const {value} = this.state;
    const currentValue = value !== undefined ? value : defaultValue;

    return (
      <div>
        <label data-hook="number-input-spinner-title" className={s.label}>
          {title}
        </label>
        <div>
          <div className={s.inputGroup} data-hook="number-input-spinner-container">
            <input
              type="number"
              data-hook="number-input-spinner-input"
              value={value}
              aria-label={title}
              onChange={this.onChange}
              ref={this.numberInputSpinnerRef}
            />
            <div data-hook="number-input-spinner-arrows-container" className={s.spinnerArrows} aria-hidden={true}>
              <span
                data-hook="number-input-spinner-up-arrow"
                className={classnames(s.upArrow, {[s.disabled]: currentValue >= max})}
                onClick={this.onUp}
              />
              <span
                data-hook="number-input-spinner-down-arrow"
                className={classnames(s.downArrow, {[s.disabled]: currentValue <= min})}
                onClick={this.onDown}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
